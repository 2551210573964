/* .footer {
	border-top: 2px solid rgba(224, 228, 238, 1);
	width: 100%;
	height: auto;
	padding-bottom: 38px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
} */

.footer-container {
	border-top: 2px solid rgba(224, 228, 238, 1);
	width: 100%;
	height: auto;
	padding-bottom: 38px;
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
}

.container-links {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	gap: 14px;
}

.container-links__title {
	color: rgba(34, 34, 34, 1);
	font-weight: 500;
	font-size: 16px;
	line-height: 24.2px;
}

.container-links__links {
	color: rgba(159, 161, 164, 1);
	font-weight: 500;
	font-size: 14px;
	line-height: 21.78px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.container-links__links-com {
	color: rgba(159, 161, 164, 1);
	font-weight: 500;
	font-size: 14px;
	line-height: 21.78px;
	display: flex;
	gap: 10px;
}

@media (max-width: 861px) {
	/* .footer {
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 20px;
	} */
	.footer-container {
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 20px;
	}
}

.container-links__links-item-com {
	color: #9FA1A4;
	font-weight: 400;
	font-size: 12px;
	line-height: 19.36px;
	background-color: #FFFFFF;
	border-radius: 14px;
	box-shadow: 0px 7px 64px 8px rgba(137, 159, 195, 0.2);
	padding: 4px 8px;
}

.footer-container-a {
	display: none;
	border-top: 2px solid rgba(224, 228, 238, 1);
	width: 100%;
	height: auto;
	padding-bottom: 38px;
	padding-top: 20px;
	justify-content: space-between;
}

.container-links-a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	gap: 21px;
}

.container-other-a {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 14px;
	color: #4B6083;
	font-weight: 300;
	font-size: 18.22px;
	line-height: 22.05px;
}

.container-other-a img {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 561px) {
	.footer-container {
		display: none;
	}
	.footer-container-a {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
	.container-links {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		width: 100%;
	}
	.container-links img {
		width: 8px;
		height: 6px;
	}
}