body, html {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: rgb(222,231,248);
  background: -moz-linear-gradient(90deg, rgba(222,231,248,0.8) 0%, rgba(249,251,255,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(222,231,248,0.8) 0%, rgba(249,251,255,1) 100%);
  background: linear-gradient(90deg, rgba(222,231,248,0.8) 0%, rgba(249,251,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dee7f8",endColorstr="#f9fbff",GradientType=1);
}

body.dark {
  background: rgb(16,29,52);
  background: -moz-linear-gradient(90deg, rgba(16,29,52,1) 0%, rgba(13,11,20,1) 50%, rgba(31,12,28,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(16,29,52,1) 0%, rgba(13,11,20,1) 50%, rgba(31,12,28,1) 100%);
  background: linear-gradient(90deg, rgba(16,29,52,1) 0%, rgba(13,11,20,1) 50%, rgba(31,12,28,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#101d34",endColorstr="#1f0c1c",GradientType=1);
}

.dark .container-menu-item {
  color: #6B8FC9;
}

.dark .active {
  color: #3F82F4;
}

.dark .container-other-wallet {
  color: #6B8FC9;
  background-color: #202847;
}

.dark .container-window {
  background-color: #141823;
  box-shadow: none;
}

.dark .container-window__header-start__item {
  color: #FFFFFF;
}

.dark .gray {
  color: #7E8FAA;
}

.dark .container-window__main-container {
  background-color: #0E1018;
}

.dark .text-c {
  color: #FFFFFF;
}

.dark .border {
  border: 2px solid #252449;
}

.dark .container-window__circle {
  background-color: #303D4E;
  box-shadow: none;
}

.dark .slippage-options {
  background-color: #0E1018;
}

.dark .active-option {
  background-color: #2E384B;
  color: #FFFFFF;
}

.dark .option {
  color: #FFFFFF;
}

.dark .container-links__title {
  color: #FFFFFF;
}

.dark .container-links__links-item-com {
  background-color: #403E58;
  color: #FFFFFF;
  box-shadow: none;
}

.dark .footer-container, .footer-container-a {
  border-top: 2px solid #252449;
}

.dark .liquidity__title {
  color: #FFFFFF;
}

.dark .liquidity__description {
  color: #9FA1A4;
}

.dark .liquidity__button--video {
  border: 2px solid #252449;
  color: #FFFFFF;
}

.dark .liquidity__tab--active {
  color: #FFFFFF !important;
  border-bottom: 2px solid #FFFFFF;
}

.dark .liquidity__tab {
  color: #7A7A7A;
}

.dark .liquidity__header {
  background-color: #141823;
}

.dark .liquidity__search-input {
  background-color: #20263F;
  color: #4B6083 !important;
}

.dark .liquidity__search-input input {
  color: #4B6083 !important;
  font-family: 'Inter';
}

.dark .liquidity__select {
  color: white;
}

.dark .liquidity__table {
  background-color: #141823;
}

.dark .liquidity__table-item__pair-name {
  color: white;
}

.dark .liquidity__table-item__tvl-value {
  color: white;
}

.dark .liquidity__table-item {
  border-top: 1px solid #252449;
}

.dark .liquidity__table-item__tvl-title {
  color: #4B6083;
}

.dark .liquidity__details-section h3 {
  color: #667EA7;
}

.dark .liquidity__details-section p {
  color: #38455C;
}

.dark .liquidity__button-withdraw {
  background-color: #2D3554;
  color: white;
}

.dark .modal {
  background-color: #141823;
}

.dark .modal-content {
  background-color: transparent;
}

.dark .title-modal-header {
  color: white;
}

.dark .token-list__title {
  color: white;
}

.dark .token-info__symb {
  color: white;
}

.dark .token-info__price {
  color: #4B6083;
}

.dark .modal-body input {
  background-color: #0E1018;
  color: #515874;
}

.dark .token-value {
  color: white;
}

.dark .container-window__search input {
  background-color: #0E1018;
  color: #515874;
}

.dark .container-window__main-token {
  border: 2px solid #252449;
}

.dark .token-container__symb {
  color: #FFFFFF;
}

.dark .container-window__main-notify {
  background-color: #0E1018;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.dark .item__title {
  color: #4B6083 !important;
}

.dark .inff {
  background-color: #2D3554 !important;
  color: white !important;
}

.dark .item__value {
  color: #38455C !important;
}

.dark .dropdown-content {
  background: rgb(16,29,52);
  background: -moz-linear-gradient(90deg, rgba(16,29,52,1) 0%, rgba(13,11,20,1) 50%, rgba(31,12,28,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(16,29,52,1) 0%, rgba(13,11,20,1) 50%, rgba(31,12,28,1) 100%);
  background: linear-gradient(90deg, rgba(16,29,52,1) 0%, rgba(13,11,20,1) 50%, rgba(31,12,28,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#101d34",endColorstr="#1f0c1c",GradientType=1);  box-shadow: none;
}

.dark .line {
  background-color: #667B9D;
}