@keyframes modal-desktop-content-show {
	from {
		opacity: 0;
		transform: scale(0.9);
	}

	to {
		opacity: 1;
		transform: translateY(1.1);
	}
}

.modal {
	background-color: rgb(0, 0, 0, 0.4) !important;
	z-index: 1000;
	padding-top: 16px;
	width: 460px;
	padding: 16px 18px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 7px 64px 8px rgba(137, 159, 195, 0.2);
	border-radius: 24px;
	gap: 22px;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.modal.show {
	opacity: 1;
	transition: 0.2s ease;
}

.modal-content {
	background-color: #F9FBFF;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 460px;
	padding: 16px 18px;
	border-radius: 15px;
	animation: modal-desktop-content-show 0.4 ease;
}

.modal-header {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 25px;
}

.modal-header-one {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-weight: 500;
}

.modal-header-two {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.close {
	font-size: 28px;
	font-weight: bold;
	cursor: pointer;
}

.modal-body {
	margin-top: 15px;
	flex-grow: 1;
}

.modal-body input {
	background-color: #F2F2F2;
	border-radius: 5px;
	text-align: left !important;
	padding: 10px;
	box-sizing: border-box;
	font-weight: 400;
	font-size: 14px;
	color: #A5A5A5;
	font-family: 'Inter';
}

.token-list {
	margin-top: 20px;
}

.token-item {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid #ddd;
}

.container-other {
	width: 100% !important;
	display: flex;
	justify-content: space-between;
}

@media (max-width: 490px) {
	.container-other-wallet {
		padding: 12px 20px !important;
		font-size: 16px !important;
	}
}

@media (max-width: 438px) {
	.container-other-wallet img {
		width: 26px !important;
	}
}

.token-list-table {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.token-list-table-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.token-table-item__start {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
}

.token-info {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.token-table-item__end {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.token-info__symb {
	font-weight: 500;
	font-size: 16px;
	color: #313131;
}

.token-info__price {
	font-weight: 400;
	color: #4B6083;
	font-size: 14px;
}

.token-value {
	font-weight: 500;
	font-size: 16px;
	color: #313131;
}

.token-value__price {
	font-weight: 400;
	color: #4B6083;
	font-size: 14px;
}

.token-list {
	display: flex;
	flex-direction: column;
	gap: 8px;
	font-weight: 500;
}

@media (max-width: 440px) {
	.modal {
		box-sizing: border-box;
		width: 100%;
		border-radius: 0;
	}

	.modal-content {
		box-sizing: border-box;
		width: 100%;
		bottom: 0;
		position: fixed;
	}
}