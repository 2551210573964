.header {
	width: 100%;
	height: 46px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 38px;
	flex-direction: column;
}

.header-container {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-container-a {
	width: 100%;
	height: auto;
	display: none;
	justify-content: space-between;
	align-items: center;
}

.container-start {
	display: flex;
	gap: 42px;
}

.container-menu {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 39px;
}

.container-menu-a {
	display: none;
	justify-content: center;
	align-items: center;
	gap: 39px;
}

.container-menu-item {
	cursor: pointer;
	color: rgba(34, 34, 34, 1);
	font-weight: 400;
	font-size: 14px;
	line-height: 21.78px;
	height: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
	border-bottom: 1px solid rgb(0, 0, 0, 0);
	gap: 5px;
}

.active {
	color: rgba(63, 130, 244, 1);
	border-bottom: 1px solid rgba(63, 130, 244, 1);
}

.container-other {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 36px;
}

.container-other-wallet {
	color: rgba(107, 143, 201, 1);
	font-weight: 500;
	font-size: 14px;
	line-height: 24.2px;
	background-color: rgba(222, 236, 253, 1);
	padding: 8px 23px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.container-other-select {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 14px;
	cursor: pointer;
}

.container-other-select__language {
	color: rgba(75, 96, 131, 1);
	font-weight: 300;
	font-size: 22px;
	line-height: 31.63px;
	cursor: pointer;
}

.dropdown {
	position: relative;
}

.visibility {
	visibility: hidden;
	height: 40px;
}

.dropdown-content {
	display: none;
	flex-direction: column;
	gap: 18px;
	position: absolute;
	left: 0;
	top: 30px;
	z-index: 1;
	padding: 19px 8px;
	width: 401px;
	background-color: #FFFFFF;
	box-shadow: 0px 7px 64px 8px #8F92B73D;
	border-radius: 15px;
}

.dropdown-content-item {
	display: flex;
	align-items: center;
	gap: 16px;
	color: #313131;
	cursor: pointer;
}

.dropdown-content-list {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.title {
	font-weight: 500;
	font-size: 14px;
	line-height: 21.78px;
}

.desc {
	font-weight: 500;
	font-size: 10px;
	line-height: 16.94px;
}

.actives {
	color: #6B8FC9;
}

.dropdown-content a:hover {
	background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
	display: flex;
}

.container-other-wallet-a {
	display: none;
	color: rgba(107, 143, 201, 1);
	font-weight: 500;
	font-size: 16px;
	line-height: 24.2px;
	background-color: rgba(222, 236, 253, 1);
	padding: 10px 25px;
	border-radius: 8px;
	justify-content: center;
	align-items: center;
	gap: 6px;
}

.soon {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	font-weight: 500;
	font-size: 8px;
	color: #FFFFFF;
}

span {
	padding: 0;
	margin: 0;
}

.soon span {
	background-color: #19B8ED;
	border-radius: 4px;
	padding: 0 4px;
}

@media (max-width: 1340px) {
	.container-other {
		display: none !important;
	}

	.container-other-a {
		display: flex;
	}

	.container-logo {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.pc img {
	width: 200px;
}

@media (max-width: 848px) {
	.container-start {
		flex-direction: column;
		gap: 21px;
		width: 100%;
	}

	.header-container {
		display: none;
	}

	.header-container-a {
		display: flex;
	}

	.container-menu {
		display: flex;
		justify-content: flex-start;
	}

	.container-logo img {
		width: 24px;
	}
}

@media (max-width: 340px) {

	/* .container-menu-item {
		font-size: 10px;
	} */
	.container-menu {
		max-width: 100%;
		overflow-x: scroll;
		height: 44px;
	}
}

@media (max-width: 1640px) {
	.container-other-wallet {
		font-size: 12px !important;
	}

	.container-other-select__language {
		font-size: 14px !important;
	}

	.container-other-select__exit img {
		width: 20px;
	}

	.container-other-select__theme img {
		width: 16px;
	}

	.container-other-wallet img {
		width: 16px !important;
		height: 16px !important;
	}

	.container-menu-item {
		font-size: 12px !important;
	}
}

@media (max-width: 940px) {
	.container-menu-item {
		font-size: 14px !important;
	}

	.pc img {
		width: 90% !important;
	}
}

@media (max-width: 440px) {
	.container-links__title {
		font-size: 13px !important;
	}
}