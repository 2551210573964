.wrapper {
	width: 1640px;
	min-height: 100vh;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

@media (max-width: 1640px) {
	.wrapper {
		width: 1540px;
	}
}

@media (max-width: 1540px) {
	.wrapper {
		width: 1440px;
	}
}

@media (max-width: 1440px) {
	.wrapper {
		width: 1340px;
	}
}

@media (max-width: 1340px) {
	.wrapper {
		width: 1240px;
	}
}

@media (max-width: 1240px) {
	.wrapper {
		width: 1140px;
	}
}

@media (max-width: 1140px) {
	.wrapper {
		width: 1080px;
	}
}

@media (max-width: 1040px) {
	.wrapper {
		width: 1020px;
	}
}

@media (max-width: 940px) {
	.wrapper {
		width: 920px;
	}
}

@media (max-width: 840px) {
	.wrapper {
		width: 820px;
	}
}

@media (max-width: 778px) {
	.wrapper {
		width: 758px;
	}
}

@media (max-width: 740px) {
	.wrapper {
		width: 720px;
	}
}

@media (max-width: 640px) {
	.wrapper {
		width: 620px;
	}
}

@media (max-width: 594px) {
	.wrapper {
		width: 574px;
	}
}

@media (max-width: 540px) {
	.wrapper {
		width: 520px;
	}

	.title {
		font-size: 11px;
	}

	.desc {
		font-size: 9.5px;
	}

	.dropdown-content {
		display: none;
		flex-direction: column;
		gap: 18px;
		position: absolute;
		left: 0;
		z-index: 1;
		padding: 12px 27px;
		width: 200px;
		background-color: #FFFFFF;
		box-shadow: 0px 7px 64px 8px #8F92B73D;
		border-radius: 15px;
	}
}

@media (max-width: 490px) {
	.wrapper {
		width: 470px;
	}
}

@media (max-width: 440px) {
	.wrapper {
		width: 420px;
	}
}

@media (max-width: 400px) {
	.wrapper {
		width: 380px;
	}
}

@media (max-width: 340px) {
	.wrapper {
		width: 320px;
	}

	.token-container__symb {
		font-size: 20px !important;
	}

	.token-container__name {
		font-size: 12px !important;
	}

	.notify-text {
		font-size: 14px !important;
	}

	/* .container-window__header-start {
		font-size: 11px;
	} */

	.container-window__header img {
		width: 12px;
	}

	.downselect img {
		width: 12px !important;
	}

	.container-window__circle img {
		width: 12px;
	}

	.slippage img {
		width: 12px;
	}

	/* .text-button {
		font-size: 7.5px !important;
		padding: 2px 6px !important;
		line-height: 9.29px !important;
	} */

	.container-slippage-container__start {
		gap: 8px !important;
	}

	.container-slippage-container__end {
		gap: 4px !important;
	}

	/* .button {
		font-size: 11px !important;
		padding: 8px 0 !important;
		border-radius: 8px !important;
	} */

	.container {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.container-window__main-container {
		gap: 7px !important;
	}

	.container-links-a {
		gap: 12px !important;
	}
}

.container-window__search {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.container-window__main-token {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-radius: 10px;
	border: 2px solid #F3F5FA;
	padding: 20px 12px;
	box-sizing: border-box;
}

.container-window__main-token__start {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 9px;
}

.token-container__symb {
	color: #313131;
	font-weight: 400;
	font-size: 23px;
}

.token-container__name {
	color: #4B6083;
	font-weight: 400;
	font-size: 18px;
}

.token-container__name-c {
	color: #4B6083;
	font-weight: 400;
	font-size: 11px;
}

.container-window__main-notify {
	padding: 8px;
	font-weight: 400;
	font-size: 16px;
	line-height: 21.78px;
	background-color: #F3F5FA;
	border-radius: 17.46px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
}

.token-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 8px;
}

.contract {
	align-items: flex-end;
}

.container-window__search input {
	width: 100%;
	text-align: left !important;
	color: #6F6F6F;
	font-family: 'Inter';
	font-weight: 400;
	font-size: 13px;
	background: #F2F2F2;
	border-radius: 10px;
	padding: 12px 12px;
}

input {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #313131;
	font-weight: 400;
	font-size: 23px;
	gap: 10px;
	border: 0;
	outline: 0;
	width: 100%;
	text-align: right;
	background-color: transparent;
}

@media (max-width: 290px) {
	.wrapper {
		width: 190px;
	}

	.title {
		font-size: 10px;
	}

	.desc {
		font-size: 8px;
	}

	.dropdown-content {
		display: none;
		flex-direction: column;
		gap: 18px;
		position: absolute;
		left: 0;
		z-index: 1;
		padding: 8px 20px;
		width: 140px;
		background-color: #FFFFFF;
		box-shadow: 0px 7px 64px 8px #8F92B73D;
		border-radius: 15px;
	}
}

.container {
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 90px;
	padding-bottom: 390px;
}

.container-window {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	padding: 16px 18px;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 7px 64px 8px rgba(137, 159, 195, 0.2);
	border-radius: 24px;
	color: rgba(49, 49, 49, 1);
	width: 460px;
	gap: 22px;
}

.forcircle {
	display: flex;
	flex-direction: column;
	gap: 14px;
	position: relative;
}

.container-window__header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
	font-size: 16px;
	line-height: 24.2px;
}

.gray {
	color: rgba(126, 143, 170, 1);
}

.container-window__header-start {
	display: flex;
	align-items: center;
	gap: 26px;
}

.container-window__footer {
	width: 100%;
}

.container-window__footer-button {
	width: 100%;
}

.button {
	width: 100%;
	color: rgba(255, 255, 255, 1);
	font-weight: 500;
	font-size: 16px;
	line-height: 24.2px;
	background: rgb(98, 107, 239);
	background: -moz-linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	background: linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#626bef", endColorstr="#23cae2", GradientType=1);
	padding: 16px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 18px;
}

.container-window__main {
	width: 100%;
	color: #4B6083;
	font-weight: 400;
	font-size: 11px;
	line-height: 16.94px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.container-window__main-container {
	display: flex;
	flex-direction: column;
	gap: 14px;
	background-color: #F3F5FA;
	padding: 12px;
	border-radius: 18px;
}

.container-window__main-container__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container-window__main-container__main {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container-window__main-container__footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.value {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #313131;
	font-weight: 400;
	font-size: 19px;
	line-height: 30.26px;
	gap: 10px;
}

.border {
	background-color: transparent;
	border: 2px solid #F3F5FA;
}

.slippage {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px;
	border-radius: 18px;
}

.container-slippage-container__start {
	display: flex;
	align-items: center;
	gap: 17px;
}

.container-slippage-container__end {
	display: flex;
	align-items: center;
	gap: 17px;
}

.container-slippage-container__end img {
	cursor: pointer;
}

.container-window__circle {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	position: absolute;
	top: calc(50% - 25px);
	left: 50%;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 7px 64px 8px rgba(137, 159, 195, 0.2);
}

.container-window__header-end {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.container-window__header-end img {
	transition: 0.8s ease;
	cursor: pointer;
}

.container-window__header-end img:hover {
	transform: rotate(180deg);
}

@media (max-width: 440px) {
	.container-window__search input {
		font-size: 11px !important;
	}

	.token-container__symb {
		font-size: 15px !important;
	}

	.token-container__name {
		font-size: 10px !important;
	}

	.token-container {
		gap: 2px !important;
	}

	.token-img img {
		width: 18px !important;
	}

	.notify-text {
		font-size: 12px !important;
	}
}