.wrapper {
	width: 1400px;
	min-height: 100vh;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.container-p {
	display: flex;
	padding-top: 79px;
	padding-bottom: 38px;
	flex-grow: 1;
	justify-content: flex-start;
	flex-direction: column;
	gap: 60px;
}


@media (max-width: 1680px) {
	.wrapper {
		width: 1440px;
	}
}
/* 
@media (max-width: 1640px) {
	.wrapper {
		width: 1540px;
	}
}

@media (max-width: 1580px) {
	.wrapper {
		width: 1480px;
	}
}

@media (max-width: 1520px) {
	.wrapper {
		width: 1420px;
	}
} */

@media (max-width: 1620px) {
	.wrapper {
		width: 1300px;
	}
}

@media (max-width: 1300px) {
	.wrapper {
		width: 1240px;
	}
}

@media (max-width: 1240px) {
	.wrapper {
		width: 1100px;
	}
}

@media (max-width: 1280px) {
	.wrapper {
		width: 1180px;
	}
}

@media (max-width: 1220px) {
	.wrapper {
		width: 1120px;
	}

	.liquidity__header {
		background-color: transparent !important;
		padding: 0 !important;
		padding-bottom: 19px !important;
	}

	.liquidity__table {
		background-color: transparent !important;
		padding: 0 !important;
	}

	.liquidity__table-item__value-my:nth-child(2),
	.liquidity__table-item__value-my:nth-child(4),
	.liquidity__table-item__value-my:nth-child(5),
	.liquidity__table-item__value-my:nth-child(7),
	.liquidity__table-item__value-my:nth-child(8) {
		display: none;
	}

	.liquidity__table-item-buttons {
		display: flex !important;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
	}

	/* .liquidity__header, .liquidity__table {
		padding: 26px 24px !important;
	} */
	.liquidity__table-item-info-t {
		display: flex !important;
	}
}

@media (max-width: 1150px) {
	.wrapper {
		width: 1050px;
	}

	.liquidity__tab {
		font-size: 24px !important;
	}
}

@media (max-width: 1090px) {
	.wrapper {
		width: 990px;
	}

	.liquidity__tab {
		font-size: 20px !important;
	}
}


@media (max-width: 1030px) {
	.wrapper {
		width: 930px;
	}
}

@media (max-width: 970px) {
	.wrapper {
		width: 870px;
	}
}

@media (max-width: 910px) {
	.wrapper {
		width: 810px;
	}

	.liquidity__tab {
		font-size: 16px !important;
	}

	.liquidity__tabs {
		max-width: 100%;
		overflow-x: scroll;
		white-space: nowrap;
	}

	.liquidity__button--video {
		display: none !important;
	}
}

@media (max-width: 850px) {
	.wrapper {
		width: 750px;
	}
}

@media (max-width: 790px) {
	.wrapper {
		width: 690px;
	}
}

@media (max-width: 730px) {
	.wrapper {
		width: 630px;
	}
}

@media (max-width: 670px) {
	.wrapper {
		width: 570px;
	}
}

@media (max-width: 610px) {
	.wrapper {
		width: 510px;
	}
}

@media (max-width: 520px) {
	.wrapper {
		width: 458px;
	}

	.absolute {
		font-size: 7px !important;
		right: -26px !important;
	}

	.liquidity__select {
		display: none !important;
	}

	.liquidity__select-m {
		display: flex !important;
	}
}

@media (max-width: 440px) {
	.wrapper {
		width: 410px;
	}

	.liquidity__tabs {
		margin-bottom: 21px !important;
	}

	.liquidity__title {
		text-align: center !important;
		font-size: 28px !important;
	}

	.liquidity__description {
		text-align: center !important;
		font-size: 15px !important;
		margin-bottom: 20px !important;
	}

	.liquidity__button {
		padding: 12px 19px !important;
		font-size: 15px !important;
		font-weight: 500 !important;
		font-family: 'Inter';
	}

	.liquidity__tab {
		font-size: 15px !important;
	}

	.liquidity__search-input {
		font-size: 12px !important;
		padding: 12px !important;
		height: 35px !important;
	}

	.liquidity__select-m img {
		width: 14px !important;
	}

	.liquidity__select-m {
		padding: 12px !important;
	}

	.two-image {
		top: -7px !important;
		left: -8px !important;
	}

	.two-image img {
		width: 17px !important;
	}

	.one-image img {
		width: 17px !important;
	}

	.liquidity__table-item-buttons_item {
		font-size: 11px !important;
	}

	.liquidity__actions {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.liquidity__table-item__pair-name {
		font-size: 12px !important;
	}

	.liquidity__table-item__tvl-value {
		font-size: 10px !important
	}

	.liquidity__table-item__tvl-title {
		font-size: 9px !important;
	}

	.liquidity__table-item__tvl {
		gap: 4px !important;
	}

	h3 {
		font-size: 14px !important;
	}

	p {
		font-size: 13px !important;
	}

	.liquidity__button-withdraw,
	.liquidity__button-deposit {
		font-size: 11px !important;
		width: 100% !important;
	}

	.liquidity__details-section {
		width: 100% !important;
	}

	.liquidity__details-actions {
		gap: 10px !important;
	}

	.item__title {
		font-size: 11px !important;
	}

	.item__value {
		font-size: 12px !important;
	}
}

@media (max-width: 440px) {
	.wrapper {
		width: 400px;
	}

	.liquidity__table-item__pair-name {
		font-size: 12px !important;
	}

	.liquidity__table-item__tvl-value {
		font-size: 14px !important
	}

	.liquidity__table-item__tvl-title {
		font-size: 10px !important;
	}
}

@media (max-width: 420px) {
	.wrapper {
		width: 390px;
	}

	.liquidity__table-item__tvl-value {
		font-size: 12px !important
	}

	.liquidity__table-item__tvl-title {
		font-size: 8px !important;
	}

	/* .liquidity__table-item-buttons_item {
		font-size: 14px !important;
	} */

	button .liquidity__button-withdraw {
		font-size: 12px !important;
	}
}

@media (max-width: 410px) {
	.wrapper {
		width: 380px;
	}
}

@media (max-width: 390px) {
	.wrapper {
		width: 360px;
	}
}

@media (max-width: 370px) {
	.wrapper {
		width: 340px;
	}
}

@media (max-width: 350px) {
	.wrapper {
		width: 320px;
	}

	.container-window {
		padding: 18px 16px !important;
	}
}

@media (max-width: 330px) {
	.wrapper {
		width: 300px;
	}
}

@media (max-width: 310px) {
	.wrapper {
		width: 270px;
	}
}

.liquidity__title {
	font-size: 30px;
	font-weight: 500;
	color: #313131;
}

.container-pb {
	display: flex;
	width: 100%;
	gap: 60px;
	align-items: center;
}

.progress-bar {
	width: 100%;
	box-sizing: border-box;
}

.progress-bar progress {
	width: 100%;
	height: 4px;
}

progress {
	background-color: gray;
	border: 0;
	height: 4px;
	border-radius: 9px;
}

progress::-webkit-progress-bar {
	background-color: gray;
	border: 0;
	height: 4px;
	border-radius: 9px;
}

progress::-webkit-progress-value {
	background-color: #14A553;
	border: 0;
	height: 4px;
	border-radius: 9px;
}

progress::-moz-progress-bar {
	background-color: #14A553;
}

.item__title {
	font-weight: 600;
	font-size: 8px;
	color: #303030;
}

.item__value {
	font-weight: 600;
	font-size: 9px;
	color: #858585;
}

.left {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.right {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	text-align: right;
}

.liquidity__description {
	font-weight: 400;
	color: #525252;
	font-size: 18px;
	margin-bottom: 57px;
}

.liquidity__actions {
	display: flex;
	gap: 10px;
	margin-bottom: 60px;
}

.liquidity__button {
	padding: 20px;
	border: none;
	border-radius: 14px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 18px;
}

.liquidity__table-item-buttons_item {
	padding: 7px 20px;
	background-color: #D4E1F6;
	border-radius: 5px;
	font-weight: 500;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	gap: 10px;
}

.liquidity__table-item-buttons {
	display: none;
	justify-content: space-between;
}

.liquidity__button--add {
	background: rgb(98, 107, 239);
	background: -moz-linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	background: linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#626bef", endColorstr="#23cae2", GradientType=1);
	color: white;
	font-weight: 500;
	font-size: 16px;
}

.liquidity__button--video {
	background: rgb(0, 0, 0, 0);
	border: 2px solid #C6D7F4;
	font-weight: 500;
	font-size: 16px;
}

.liquidity__tabs {
	display: flex;
	gap: 74px;
	margin-bottom: 60px;
	height: 44px;
}

.liquidity__tab {
	cursor: pointer;
	color: #7A7A7A;
	font-weight: 600;
	font-size: 18px;
	border: 0;
	outline: 0;
}

.liquidity__tab--active {
	border-bottom: 2px solid #313131;
	color: #313131;
}

button {
	padding: 0;
	margin: 0;
	background-color: transparent;
	border: 0;
	outline: 0;
}

.liquidity__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
}

.liquidity__select {
	display: flex;
	gap: 24px;
}

.liquidity__select-m {
	border: 1px solid #DEECFD;
	border-radius: 7px;
	padding: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	display: none;
}

.tvl {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 4px;
}

.apr {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 4px;
}

.liquidity__search-input {
	padding: 0 16px;
	border: 0;
	outline: 0;
	background-color: #DEECFD;
	color: #4B6083;
	font-weight: 400;
	font-size: 12px;
	border-radius: 12px;
	text-align: left !important;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	box-sizing: border-box;
	height: 44px;
}

.liquidity__search-input input {
	text-align: left !important;
}

input {
	font-family: 'Inter';
}

.liquidity__pagination {
	display: flex;
	gap: 5px;
}

.liquidity__pagination-button {
	padding: 5px 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
}

.liquidity__table {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	margin-bottom: 32px;
}

.liquidity__table-item {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.liquidity__table-item-info-t {
	display: none;
}

.gradient {
	background: rgb(98, 107, 239);
	background: -moz-linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	background: linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#626bef", endColorstr="#23cae2", GradientType=1);
	color: #FFFFFF;
}

.liquidity__table-item__pair {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
}

.liquidity__table-item__pair-images img {
	width: 28px;
}

.one-image {
	position: relative;
}

.two-image {
	position: absolute;
	top: -12px;
	left: -16px;
}

.liquidity__table-item__pair-name {
	font-weight: 600;
	font-size: 16px;
	color: #313131;
}

.liquidity__table-item__pair-name {
	position: relative;
}

.absolute {
	position: absolute;
	top: -4px;
	right: -34px;
	color: #6A70FF;
	font-size: 10px;
	font-weight: 400;
	background-color: #DBD3FD;
	border-radius: 2px;
	padding: 2px 3px;
}

.liquidity__table-item__tvl {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;
	gap: 16px;
}

.liquidity__table-item__tvl-title {
	color: #525252;
	font-weight: 400;
	font-size: 10px;
}

.liquidity__table-item__tvl-value {
	color: #313131;
	font-weight: 600;
	font-size: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
}

.liquidity__table-item__tvl-value-img {
	display: flex;
	justify-content: center;
	align-items: center;
}

.liquidity__table-item__tvl-value-img img {
	width: 24px;
}

.green {
	color: #14A553;
}

.liquidity__header {
	background-color: #FFFFFF;
	border-radius: 15px 15px 0px 0px;
	padding: 16px 34px;
	box-sizing: border-box;
}

.liquidity__table {
	background-color: #FFFFFF;
	border-radius: 0px 0px 15px 15px;
	padding: 26px 34px;
	padding-top: 0px;
	box-sizing: border-box;
}

.container-liquidity__table-item {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
}

.liquidity__table-item-info {
	display: none;
	width: 100%;
	padding: 20px 0px;
}

.container-liquidity__table-item .liquidity__table-item-info {
	display: flex;
}

.liquidity__table-item-info-t {
	flex-direction: column;
	align-items: flex-start !important;
	justify-content: flex-start !important;
	width: 100% !important;
	flex-wrap: wrap !important;
}

.liquidity__details {
	flex-wrap: wrap !important;
}

.liquidity__details {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.liquidity__details-actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 10px;
}

h3 {
	color: #525252;
	font-weight: 600;
	font-size: 14px;
}

p {
	color: #858585;
	font-weight: 600;
	font-size: 12px;
}

.liquidity__button-withdraw {
	color: #313131;
	font-weight: 500;
	font-size: 14px;
	border-radius: 5px;
	background-color: #D4E1F6;
	padding: 7px 20px;
	font-family: 'Inter' !important;
	width: 100%;
}

.liquidity__button-deposit {
	color: #FFFFFF;
	font-weight: 500;
	font-size: 12px;
	border-radius: 5px;
	background-color: #3A86F6;
	padding: 7px 20px;
	font-family: 'Inter' !important;
	width: 100%;
}

.liquidity__table-item {
	border-top: 1px solid #C6D7F4;
	padding-top: 25px;
	padding-bottom: 10px;
}

.liquidity__details-section {
	width: 250px;
}

.liquidity__table-item__value--button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 14px 40px;
	font-size: 16px;
	border-radius: 12px;
	color: #FFFFFF;
	background: rgb(98, 107, 239);
	background: -moz-linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	background: linear-gradient(90deg, rgba(98, 107, 239, 1) 0%, rgba(35, 202, 226, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#626bef", endColorstr="#23cae2", GradientType=1);
}



@media (max-width: 768px) {

	.liquidity__table-item__value:nth-child(3),
	.liquidity__table-item__value:nth-child(4),
	.liquidity__table-item__value:nth-child(6) {
		display: none;
	}
}

.line {
	width: 100%;
	background-color: #C6D7F4;
	height: 1px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.liquidity__button--add, .liquidity__table-item__value--button {
	transition: 0.2s ease;
	cursor: pointer;
}

.liquidity__button--add:hover, .liquidity__table-item__value--button:hover {
	transform: scale(1.005);
}

.fade-in {
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}